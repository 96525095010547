import { Injectable } from '@angular/core';
import { Limit } from '@type/limits';
import { deepCopyObj } from '@utils/copy-obj';
import { LIMIT } from '../../utils/constants';

@Injectable()
export class StrategyLimitStateService {
  #limit: Limit = deepCopyObj(LIMIT);

  #limitCopy: Limit | null = null;

  resetLimit = () => {
    this.#limit = deepCopyObj(LIMIT);
  };

  setLimit(limit: Limit) {
    this.#limit = limit;
    this.#limitCopy = deepCopyObj(limit);
  }

  getCurrentLimit() {
    return this.#limit;
  }

  getCurrentLimitCopy() {
    return this.#limitCopy;
  }
}
