import { Injectable, inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Operator } from '@enums/operator';
import { createBooleanFieldOptions } from '@modules/directives/customForm/boolean-field-options';
import { TranslateService } from '@ngx-translate/core';
import { CreateLimit, Limit, UpdateLimit } from '@type/limits';
import { MENU_OPTIONS } from '../../utils/constants';
import { StrategyLimitStateService } from '../strategy-limit-state/strategy-limit-state.service';

@Injectable()
export class StrategyLimitFormService {
  private translate = inject(TranslateService);

  private controlListWithValidator: readonly NgModel[] = [];

  limitState = inject(StrategyLimitStateService);

  get limit() {
    return this.limitState.getCurrentLimit();
  }

  propertyOperatorOptionList = this.getPropertyOperatorOptionList();

  operatorMenuList = this.createOperatorMenuOptionList();

  booleanOptionList = createBooleanFieldOptions().map((el, id) => ({ ...el, id }));

  private updateControlsWithValidators = () =>
    this.controlListWithValidator
      .filter((c) => !!c.control.validator)
      .map((c) => {
        c.control.markAsTouched();
        c.control.updateValueAndValidity();
        return c;
      });

  private validate = () => this.updateControlsWithValidators();

  valid = () => {
    const controls = this.validate();
    return controls.length === controls.reduce((a, c) => a + +!!c.valid, 0);
  };

  toFieldValueAttributes = (slfv: Limit['strategy_limit_field_values'][0]) => ({
    ...slfv,
    strategy_field_value_ids: slfv.strategy_field_values.map(({ id }) => id),
  });

  convertToCreateLimit = (): CreateLimit => ({
    strategy_limit: {
      operator: this.limit.operator,
      value: this.limit.value,
      strategy_limit_field_values_attributes: this.limit.strategy_limit_field_values.map(this.toFieldValueAttributes),
      strategy_limit_items_attributes: this.limit.strategy_limit_items.map(({ item_id }) => ({ item_id })),
    },
  });

  convertToUpdateLimit = (): UpdateLimit => ({
    strategy_limit: {
      operator: this.limit.operator,
      value: this.limit.value,
      ...this.getUpdateLimitAttributes(),
    },
  });

  getUpdateLimitAttributes = () => {
    const copiedItems = this.limitState.getCurrentLimitCopy()?.strategy_limit_items || [];
    const destroyedItems = this.getDestroyedModel(copiedItems, this.limit.strategy_limit_items);
    const addedItems = this.getAddedModel(this.limit.strategy_limit_items, copiedItems);

    return {
      strategy_limit_items_attributes: [
        ...Array.from(addedItems.keys()).map((item_id) => ({ item_id })),
        ...destroyedItems.map((el) => ({ ...el, _destroy: true })),
      ],
      strategy_limit_field_values_attributes: this.limit.strategy_limit_field_values.map(this.toFieldValueAttributes),
    };
  };

  setControls(controls: readonly NgModel[]) {
    this.controlListWithValidator = controls;
  }

  private getDestroyedModel(target: { id: number }[], compared: { id: number }[]) {
    return target.filter(({ id }) => !compared.find((el) => el.id === id));
  }

  private getAddedModel(target: { id: number }[], compared: { id: number }[]) {
    return new Map(
      Object.entries(
        target
          .filter(({ id }) => id === 0 || !compared.find((el) => el.id === id))
          .reduce(
            (a, c) => {
              a[c.id] = c;
              return a;
            },
            {} as Record<number, any>,
          ),
      ),
    );
  }

  private createOperatorMenuOptionList() {
    return MENU_OPTIONS.map((o) => ({ ...o, name: this.translate.instant(o.name) }));
  }

  private getPropertyOperatorOptionList() {
    return [
      {
        name: this.translate.instant('strategy.limits.form.3.equal'),
        value: Operator['='],
      },
      {
        name: this.translate.instant('strategy.limits.form.3.not_equal'),
        value: Operator['!='],
      },
      {
        name: this.translate.instant('strategy.limits.form.3.any'),
        value: Operator.Any,
      },
    ];
  }
}
